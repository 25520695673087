/* page not found css start */
.ct_page_not_found_bg {
  background-color: #e7ffff;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
}
.wrapper.ct_main_dashboard.topbar_open nav.navbar {
  margin-top: 76px;
}
.ct_page_not_found_section {
  padding: 4rem 2rem;
}
.ct_page_not_found_section .ct_page_not_found_error {
  font-size: 150px;
  color: #008b62;
  text-shadow: 1px 1px 1px #00593e, 2px 2px 1px #00593e, 3px 3px 1px #00593e,
    4px 4px 1px #00593e, 5px 5px 1px #00593e, 6px 6px 1px #00593e,
    7px 7px 1px #00593e, 8px 8px 1px #00593e, 25px 25px 8px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
  margin-bottom: 40px;
}
.ct_page_not_found_page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
  font-family: "Roboto", sans-serif;
}
.back-home {
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0, 0, 0, 0.4);
  background: #222;
  border-radius: 6px;
}
.back-home:hover {
  background: #222;
  color: #ddd;
}

.ct_pointer_curser {
  cursor: pointer;
}

.ct_small_num_box.ct_mx_0 {
  margin-inline: 0px !important;
}

.ct_fs_16 {
  font-size: 16px;
}
.ct_back_btn {
  color: #000;
  transition: 0.4s ease all;
  font-size: 18px;
}
.ct_line_height_0 {
  line-height: 0px;
}

.ct_custom_table th {
  background-color: transparent;
  border-bottom: 0px;
  background-color: #1f2430;
  color: #fff;
  padding: 15px 15px;
  white-space: nowrap;
  text-align: center;
  text-align: start;
}
.ct_custom_table td{
  text-align: start;
}
.ct_custom_table th:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ct_custom_table th:last-child {
  border-radius: 0px 10px 10px 0px;
}
/* page not found css end */

/* Pagination Css Start */

.ct_pagination_select_21 {
  border: 1px solid #d8d8d8;
  padding: 4px;
  border-radius: 6px;
  width: 60px;
}
.pagination li.previous,
.pagination li.next {
  background-color: #ffff;
  width: auto;
  padding: 3px 10px;
  border-radius: 5px;
}

.pagination li.previous a,
.pagination li.next a {
  color: #000 !important;
}
.pagination li {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 2px;
  background-color: rgb(255 255 255 / 52%);
}
.pagination li.active {
  background: linear-gradient(
    90.11deg,
    #8c4d00 -12.94%,
    #8d4f01 -12.46%,
    #b6842d 4.18%,
    #d5ad50 19.73%,
    #eccb69 33.74%,
    #fadd78 45.73%,
    #ffe47d 54.28%,
    #ffe177 59.82%,
    #fdd866 67.19%,
    #fbca4b 75.53%,
    #f8b625 84.53%,
    #f5a300 92.01%,
    #ef9f00 98.34%,
    #de9400 106.74%,
    #c28100 116.34%,
    #9c6700 126.74%,
    #946200 128.72%
  );
}
.pagination li a {
  color: #fff !important;
}
.pagination li.active a {
  color: #000 !important;
}

/* Pagination Css End */
.ct_input_eye{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right:15px;
}
input.PhoneInputInput {
  border: 0px;
}


.PhoneInput {
  display: flex;
  align-items: center;
  height: 40px;
  border-color: #b5b5b5;
  outline: none;
}
.PhoneInput:focus{
  border: 0;
}
input.PhoneInputInput{
  outline: none;
}
.PhoneInputCountryIcon svg path {
  display: block;
}
.PhoneInputCountryIcon svg g {
  display: none;
}

.PhoneInputCountryIcon--border{
  box-shadow: unset !important;
}

.ct_disable_input {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0px;
  border-radius: 5px;
  z-index: 999999;
}

.ct_hover_white:hover{
  /* color: #fff; */
  background-color: transparent;
}

input#ct_previous_card,#ct_add_new_card {
  width: 14px;
  height: 14px;
  accent-color: #1f2430;
  cursor: pointer;
}

.ct_cloose_btn{
  background: unset;
}
.ct_cloose_btn i{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}
/* 
.ct_left_map > div {
  width: 100% !important;
  height: 500px;
} */

/* .ct_table_td_w_150 tr th,.ct_table_td_w_150 tr td{
text-align: start;
} */

.rmdp-container {
  width: 100%;
}
.rmdp-input{
  height: 55px !important;
  width: 100%;
}
.ct_right_header a:hover,.ct_right_header a.active{
  color: #004225;
  font-weight: 600;
}

.rmdp-time-picker div input {
  width: 30px !important;
}
.rmdp-input{
  padding-inline: 15px !important;
}

#ct_logout_modal .modal-content .modal-body svg {
  width: 50px;
  height: 50px;
  margin-inline: auto;
  display: block;
  margin-bottom: 20px;
  top: 0;
}
.ct_logout_text_modal:hover{
  color: rgb(220, 53, 69);
}

button.btn-close.ct_cloose_btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #aaa;
  opacity: 1;
  background-image: none;
}
button.btn-close.ct_cloose_btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: var(--green);
  opacity: 1;
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease all;
  color: #fff;
  z-index: 9;
}
button.btn-close.ct_cloose_btn  i{
  transition: 0.4s ease all;
}
button.btn-close.ct_cloose_btn:hover i{
  transform: rotate(360deg);
}
button.btn-close.ct_cloose_btn i{
  font-size: 20px;
}

span.ct_status_bg_345 {
  padding: 6px 10px;
  background-color: #F5F5F5;
  BORDER-RADIUS: 6px;
}